@mixin __FF_HISTORICAL_ORDERS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_USE_CVM_TEST_DATA($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ONE_REACH_MOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET_RATES_AND_RULES_INFO__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DTCC_INLINE_APPLY__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_EVENTSTREAM__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_FLEET__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INVENTORY_RESERVATION_DETAILS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_DRIVER_TYPES_UPDATE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DEBUG_CEC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_BOPIS_TO_QUOTE_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_CEC_HISTORICAL_ORDER_EDIT_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LEGACY_ORDER_ACTIONS_ENABLED__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_RETURN_ALL_BLOCK_ENABLED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.label {
  pointer-events: none;
}

.input {
  :global {
    .input-container {
      overflow: visible;
    }

    .simple-select {
      .react-selectize-search-field-and-selected-values.react-selectize-search-field-and-selected-values {
        font-size: rem(16);
        font-weight: 400;
      }

      .dropdown-menu {
        .simple-option {
          font-size: rem(16);
          font-weight: 400;
        }
      }
    }
  }
}

:global .react-selectize {
  border-radius: 3px;

  &.default.root-node {
    .react-selectize-control {
      .react-selectize-placeholder {
        color: $medium-light-gray;
        cursor: default;
        display: block;
        max-width: calc(100% - 10px);
        overflow: visible;
        padding: 13px 23px;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .react-selectize-search-field-and-selected-values {
        padding: initial;
        overflow: hidden;
        position: relative;

        .resizable-input {
          padding-left: calc(20px + 2px);
          border: 2px solid transparent;
          position: absolute;
          top: 0px;
          bottom: 2px;
          left: 0px;
          height: 100%; // for IE absolute positioning
          background-color: transparent;
          width: calc(
            100% + 2px
          ) !important; // override react-selectize inline width
          cursor: pointer;
        }

        .resizable-input:focus {
          cursor: auto;
        }

        .value-wrapper {
          padding-left: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;

          .simple-value {
            display: flex;
            overflow: hidden;

            > span {
              display: inline-block;
              vertical-align: middle;
              overflow: hidden;
              max-width: 101%;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .react-selectize-toggle-button-container {
        border-width: 2px;
        border-style: solid;
        border-color: transparent;
        border-left: none;
      }
    }
    &.open {
      .react-selectize-control {
        .react-selectize-search-field-and-selected-values {
          .resizable-input {
            outline: none;
            margin-right: -2px;
          }
        }
      }

      .dropdown-menu {
        top: 49px; // base top positioning + 2px for border
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        &.flipped {
          top: 1px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }

  &.dropdown-menu {
    position: absolute;
    top: 47px;
    max-height: 204px;
    width: 100%;
    overflow: auto;
    margin-left: -1px;
    background: $white;
    border: 1px solid $medium-gray;
    border-top-color: $medium-gray;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 5;

    &.flipped {
      transform: translateY(-100%);
      top: 1px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.default {
      .option-wrapper {
        font-weight: 600;
        border-bottom: 1px solid $dt-medium-light-gray;
        cursor: pointer;

        .simple-option {
          padding: 13px 23px;

          &.not-selectable {
            background-color: $light-gray;
            color: $medium-light-gray;
            cursor: default;
          }
        }

        &.highlight {
          color: $dt-blue;
        }

        &:last-child {
          border: 0;
        }
      }

      .no-results-found {
        padding: 13px;
      }
    }
  }
}
